import {number, object} from "prop-types"
import {Rectangle} from "recharts"

import {NAMED_INSIGHTS_COLORS} from "../insights-helpers"

const CompletionRateBarShape = ({height, width, x, y, payload: {rateDifference}}) => {
  const color = (() => {
    if (!rateDifference) return NAMED_INSIGHTS_COLORS.lightBlue
    if (rateDifference >= 0) return NAMED_INSIGHTS_COLORS.lightBlue
    if (rateDifference < 0) return NAMED_INSIGHTS_COLORS.red
  })()

  return <Rectangle fill={color} height={height} width={width} x={x} y={y} />
}

CompletionRateBarShape.propTypes = {
  height: number,
  payload: object,
  width: number,
  x: number,
  y: number,
}

export default CompletionRateBarShape
