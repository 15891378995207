import {number} from "prop-types"
import {Rectangle} from "recharts"

import {NAMED_INSIGHTS_COLORS} from "../insights-helpers"

const BenchmarkRateBarShape = ({height, width, x, y}) => {
  const color = NAMED_INSIGHTS_COLORS.gray

  return <Rectangle fill={color} height={height} width={width} x={x} y={y} />
}

BenchmarkRateBarShape.propTypes = {
  height: number,
  width: number,
  x: number,
  y: number,
}

export default BenchmarkRateBarShape
