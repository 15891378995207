import {List, ListItem, useTheme} from "@mui/material"

import {NAMED_INSIGHTS_COLORS} from "../insights-helpers"
import LegendIcon from "./legend-icon"

const LegendContent = () => {
  const listContainer = {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    listStyleType: "none",
    margin: 0,
    padding: 0,
    textAlign: "center",

    // Applies to all children except last
    "& > :nth-last-child(n + 2)": {
      marginRight: 10,
    },
  }
  const theme = useTheme()
  return (
    <List sx={listContainer}>
      <ListItem sx={listContainer}>
        <LegendIcon fill={NAMED_INSIGHTS_COLORS.lightBlue} />
        Outperforming
      </ListItem>
      <ListItem sx={listContainer}>
        <LegendIcon fill={NAMED_INSIGHTS_COLORS.red} />
        Underperforming
      </ListItem>
      <ListItem sx={listContainer}>
        <LegendIcon fill={theme.palette.brand.mediumGray} />
        Benchmark
      </ListItem>
    </List>
  )
}

export default LegendContent
