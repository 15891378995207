export const useCaseInputs = [
  {
    label: "Onboarding",
    value: "Onboarding",
    description:
      "Engage at account opening to ensure New and Existing members are getting the most out of their new account",
  },
  {
    label: "Cross Sell",
    value: "Cross Sell",
    description: "Establish further primacy by cross-selling products to existing base",
  },
  {
    label: "Servicing",
    value: "Servicing",
    description:
      "Replace time-consuming processes with automated campaigns, such as CD Maturity and Lease Expiration",
  },
  {
    label: "Other",
    value: "Other",
    description:
      "General Communications such as Birthday Campaigns, Branch Closings, and Educational Campaigns",
  },
]
