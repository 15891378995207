import {Box, FormControl, InputLabel, MenuItem, TextField} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import cx from "classnames"
import {arrayOf, func, number, shape, string} from "prop-types"
import {useEffect} from "react"

import JourneyAudienceInput from "components/journey-audience-input/journey-audience-input"
import JourneyDurationInput from "components/journey-duration-input/journey-duration-input"

import useFeatures from "lib/hooks/use-features"

import DOSelect from "../do-select/do-select"
import Feature from "../feature/feature"
import JourneyReentrySwitch from "../journey-reentry-switch/journey-reentry-switch"
import SuperheroBuilder1 from "../superhero/builder-1"

const fixedInputWidth = 400

const useStyles = makeStyles(theme => ({
  bigSpace: {
    marginBottom: theme.spacing(4),
  },
  campaignName: {
    width: fixedInputWidth,
  },
  campaignUseCase: {
    marginTop: theme.spacing(1),
    width: fixedInputWidth,
  },
  campaignAudience: {
    marginTop: theme.spacing(2),
    width: fixedInputWidth,
  },
}))

const MASS_MESSAGING_TEXT =
  "This simple campaign type will allow you to quickly send an approved email to an audience segment. We will not send duplicate emails to the same address, even if the address iused on multiple accounts. For this reason, personalization is disabled for this campaign type."

const setCampaignName = payload => ({type: "CAMPAIGN_NAME_SET", payload})
const setCampaignType = payload => ({type: "CAMPAIGN_TYPE_SET", payload})
const setJourneyDurationHours = payload => ({type: "CAMPAIGN_JOURNEY_DURATION_HOURS_SET", payload})
const setCampaignAudience = payload => ({type: "CAMPAIGN_AUDIENCE_SET", payload})
const setCampaignFeatures = payload => ({type: "CAMPAIGN_FEATURES_SET", payload})

const TemplateStep = ({
  wizardState: {
    campaignName,
    campaignType,
    campaignJourneyDurationHours,
    campaignAudience,
    campaignFeatures,
  },
  dispatch,
}) => {
  const classes = useStyles()

  const massMessaging = campaignType === "mass-messaging"

  const {hasFeature} = useFeatures()

  useEffect(() => {
    const allFeatures = ["campaign-audience"]
    const features = allFeatures.reduce((acc, feature) => {
      if (hasFeature(feature)) acc.push(feature)
      return acc
    }, [])
    dispatch(setCampaignFeatures(features))
  }, [dispatch, hasFeature])

  const onlyStandardType =
    !hasFeature("mass-messaging") && !hasFeature("tactical-campaign-and-account-management")

  const availableCampaignTypes = () => {
    var types = [{value: "standard", label: "Standard"}]
    if (hasFeature("mass-messaging")) types.push({value: "mass-messaging", label: "Mass Messaging"})
    if (hasFeature("tactical-campaign-and-account-management"))
      types.push({value: "tactical", label: "Tactical"})
    return types
  }

  return (
    <>
      <SuperheroBuilder1 style={{width: 320, float: "right"}} />
      {/* Extend box width to accomodate re-entry control label */}
      <Box maxWidth={460} my={4}>
        {/* Not behind an explicit Feature html tag because below boolean checks relevant features */}
        {!onlyStandardType && (
          <>
            <FormControl className={cx(classes.bigSpace)} fullWidth={true}>
              <InputLabel htmlFor="campaign-messaging-type" id="campaign-messaging-type-label">
                Campaign Type
              </InputLabel>
              <DOSelect
                id="campaign-messaging-type"
                fullWidth={true}
                labelId="campaign-messaging-type-label"
                name="campaign-messaging-type"
                onChange={t => dispatch(setCampaignType(t.target.value))}
                required={false}
                value={campaignType || "standard"}
              >
                {availableCampaignTypes().map(type => (
                  <MenuItem key={type.value} value={type.value}>
                    {type.label}
                  </MenuItem>
                ))}
              </DOSelect>
            </FormControl>
            {!!massMessaging && <p>{MASS_MESSAGING_TEXT}</p>}
          </>
        )}
        <TextField
          className={cx(classes.bigSpace, classes.campaignName)}
          autoFocus={true}
          fullWidth={true}
          helperText="Enter a campaign name to get started."
          id="campaign-name"
          label="Campaign Name"
          onChange={e => dispatch(setCampaignName(e.target.value))}
          value={campaignName}
        />
        {!massMessaging && (
          <>
            <Feature featureKey="tactical-campaign-and-account-management">
              <JourneyReentrySwitch
                helperText="Allow a contact to re-enter the campaign after journey expiration."
                label="Campaign Re-Enrollment"
                labelStyle="bold"
                onChange={e =>
                  dispatch(setCampaignType(e.target.value === "standard" ? "tactical" : "standard"))
                }
                value={campaignType}
              />
            </Feature>
            <JourneyDurationInput
              helperText="Enter how long journeys will stay active before they expire."
              labelStyle="bold"
              name="campaignJourneyDuration"
              onChange={e => dispatch(setJourneyDurationHours(e.target.value))}
              value={campaignJourneyDurationHours}
            />
            <Feature featureKey="campaign-audience">
              <JourneyAudienceInput
                classes={classes}
                name="campaignAudience"
                onChange={e => dispatch(setCampaignAudience(e.target.value))}
                value={campaignAudience}
              />
            </Feature>
          </>
        )}
      </Box>
    </>
  )
}

TemplateStep.propTypes = {
  dispatch: func.isRequired,
  wizardState: shape({
    campaignAudience: string,
    campaignFeatures: arrayOf(string),
    campaignJourneyDurationHours: number,
    campaignName: string,
    campaignType: string,
    campaignUseCase: string,
  }),
}

export default TemplateStep
