import {number, oneOfType, string} from "prop-types"

const BarLabel = ({y, value}) => {
  return (
    <text x={40} y={y - 5} textAnchor="middle" fill="#000" fontSize="14px" fontWeight="bold">
      {value}
    </text>
  )
}

BarLabel.propTypes = {
  y: number,
  value: oneOfType([string, number]).isRequired,
}

export default BarLabel
