import {ListItemIcon, ListItemText, MenuItem} from "@mui/material"
import {func, object} from "prop-types"
import {forwardRef, useState} from "react"
import {MdInsertDriveFile as DocumentEmbedIcon} from "react-icons/md"

import fileToContentBlock from "lib/file-to-content-block"

import FileBrowser from "../../../file-browser/file-browser"

const DocumentButton = forwardRef(({classes, onClose, onAddContentBlock}, ref) => {
  const [isFileBrowserOpen, setFileBrowserOpen] = useState(false)

  const onClick = () => {
    setFileBrowserOpen(true)
    onClose()
  }

  // fileToContentBlock returns type of `image` or `document` depending on
  // what's uploaded/selected so it's a little more forgiving
  const onSelect = async file => {
    onAddContentBlock(await fileToContentBlock(file))
    setFileBrowserOpen(false)
  }

  const onCloseModal = () => {
    setFileBrowserOpen(false)
  }

  return (
    <>
      <MenuItem onClick={onClick}>
        <ListItemIcon classes={{root: classes.icon}}>
          <DocumentEmbedIcon />
        </ListItemIcon>
        <ListItemText primary="Document Embed" ref={ref} />
      </MenuItem>
      {isFileBrowserOpen ? (
        <FileBrowser
          onClose={onCloseModal}
          isOpen={isFileBrowserOpen}
          onSelect={onSelect}
          types="*"
        />
      ) : null}
    </>
  )
})

DocumentButton.propTypes = {
  onAddContentBlock: func.isRequired,
  onClose: func,
  classes: object.isRequired,
}

export {DocumentEmbedIcon}

export default DocumentButton
