import {Grid, Typography} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import {useState} from "react"

import DateFilter from "components/template-insights/date-filter"
import {insightsContextProvider, useInsights} from "components/template-insights/insights-context"
import JourneysMetrics from "components/template-insights/journeys-metrics"
import RecurringEnrollmentMetrics from "components/template-insights/recurring-enrollment-metrics"

import useFeatures from "lib/hooks/use-features"

import Box from "../box/box"
import DocumentTitle from "../document-title/document-title"
import Feature from "../feature/feature"
import CrossChannelPerformance from "./cross-channel-performance"
import GeneralInsights from "./general-insights"
import MessagePerformance from "./message-performance"
import ObjectiveMetrics from "./objective-metrics"
import ObjectivePerformanceInsightsBarchart from "./objective-performance-insights-barchart/objective-performance-insights-barchart"
import PageDetails from "./page-details"
import PagePerformance from "./page-performance"
import ProductTotalValueTable from "./product-total-value-table"
import WidgetPerformance from "./widget-performance"

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: "white",
    padding: "20px 0",
  },
  section: {
    padding: "0 20px",
  },
  statsGridItem: {
    display: "flex",
    alignItems: "center",
  },
  dl: {
    "& dt": {
      fontSize: 17,
      fontWeight: 700,
    },
    "& dd": {
      fontSize: 17,
      marginBottom: theme.spacing(2),
    },
  },
  divider: {
    height: 80,
  },
  header: {
    fontSize: 36,
    fontWeight: 200,
    marginTop: 80,
  },
}))

const PageUnits = {Total: "Total", Percent: "Percent"}

const TemplateInsights = () => {
  const {hasWidgets, insights, setFilterParams, template, templateId} = useInsights()
  const {hasFeature} = useFeatures()

  const classes = useStyles()
  const [pageUnit, setPageUnit] = useState(PageUnits.Total)
  const doesHaveWidgets = hasWidgets([
    "mobile-app-download",
    "opt-in",
    "direct-deposit",
    "card-on-file",
    "enrollment",
    "survey",
  ])

  const campaignPerformanceChartWidth = hasFeature("tactical-campaign-and-account-management")
    ? 4
    : 6

  return template && insights ? (
    <Box className={classes.container}>
      <DocumentTitle title={`Campaigns - ${template.name} - Insights`} />

      <div className={classes.section}>
        <DateFilter onSubmit={({startDate, endDate}) => setFilterParams({startDate, endDate})} />
      </div>

      <div className={classes.section}>
        <GeneralInsights classes={classes} />
      </div>

      <div className={classes.divider} />

      <div className={classes.section}>
        <ObjectivePerformanceInsightsBarchart />

        <div className={classes.divider} />

        <Feature featureKey="product-opening-value-reporting">
          <Grid container={true} spacing={0}>
            <Grid item={true} xs={12} sm={12} md={12} lg={6} xl={6}>
              <ProductTotalValueTable classes={classes} />
            </Grid>
          </Grid>
        </Feature>

        <Typography
          className={classes.header}
          style={{marginTop: 0, marginBottom: "20px"}}
          variant="h5"
        >
          Campaign Performance
        </Typography>

        <Grid container={true} spacing={3}>
          <Grid item={true} lg={12} md={12} sm={12} xl={campaignPerformanceChartWidth} xs={12}>
            <ObjectiveMetrics />
          </Grid>
          <Grid item={true} lg={12} md={12} sm={12} xl={campaignPerformanceChartWidth} xs={12}>
            <JourneysMetrics />
          </Grid>
          <Feature featureKey="tactical-campaign-and-account-management">
            <Grid item={true} lg={12} md={12} sm={12} xl={campaignPerformanceChartWidth} xs={12}>
              <RecurringEnrollmentMetrics />
            </Grid>
          </Feature>
        </Grid>

        {template.isCceEnabled && (
          <>
            <div className={classes.divider} />

            <CrossChannelPerformance />

            {!doesHaveWidgets && <div className={classes.divider} />}
          </>
        )}

        {doesHaveWidgets && (
          <>
            <div className={classes.divider} />

            <WidgetPerformance />

            <div className={classes.divider} />
          </>
        )}

        <MessagePerformance classes={classes} templateId={templateId} />

        <PagePerformance
          isLoaded={!!insights.page_stats}
          onChangeUnit={({currentTarget: {value}}) => setPageUnit(PageUnits[value])}
          pageDetails={insights.page_stats?.value.pageDetails}
          unit={pageUnit}
        />

        <PageDetails
          isLoaded={!!insights.page_stats}
          pageDetails={insights.page_stats?.value.pageDetails}
          unit={pageUnit}
        />
      </div>
    </Box>
  ) : null
}

export default insightsContextProvider(TemplateInsights)
