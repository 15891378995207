import {arrayOf, func, object, shape, string} from "prop-types"

import MessageLibrary from "../content-library/message-library"

const setMessages = payload => ({type: "MESSAGES_SET", payload})

const AddMessagesStep = ({
  currentRow: {objective, messages},
  dispatch,
  wizardState: {campaignType},
}) => (
  <MessageLibrary
    campaignType={campaignType}
    objective={objective}
    onChange={value => dispatch(setMessages(value))}
    selectedMessages={messages}
  />
)

AddMessagesStep.propTypes = {
  currentRow: shape({
    objective: shape({id: string}),
    messages: arrayOf(object),
  }),
  dispatch: func.isRequired,
  wizardState: shape({
    campaignType: string,
  }),
}

export default AddMessagesStep
