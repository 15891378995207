import {number, oneOfType, string} from "prop-types"

const BenchmarkLabel = ({y, width, value}) => {
  return (
    <text x={width - 25} y={y + 15} textAnchor="start" fill="#cccccc" fontSize="12px">
      {value}%
    </text>
  )
}

BenchmarkLabel.propTypes = {
  y: number,
  width: number,
  value: oneOfType([string, number]).isRequired,
}

export default BenchmarkLabel
