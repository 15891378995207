import Box from "@mui/material/Box"
import {arrayOf, bool, object, string} from "prop-types"

import {NAMED_INSIGHTS_COLORS} from "../insights-helpers"

const TooltipContent = ({active, label, payload: outerPayload}) => {
  if (active && outerPayload && outerPayload.length) {
    const rank = {"Objective Completion Rate": 0, Benchmark: 1, "Rate Difference": 2}

    return (
      <Box
        sx={{
          background: "white",
          border: "2px solid #cccccc",
          paddingLeft: "1em",
          paddingRight: "1em",
        }}
      >
        <p>{label}</p>
        {outerPayload
          .sort((a, b) => rank?.[a.name] - rank?.[b.name])
          .map(({color, dataKey, name, payload: innerPayload}) => {
            const {rateDifference, objectiveCompletionRate} = innerPayload

            const tooltipBuilder = {
              "Objective Completion Rate": (() => {
                if (!objectiveCompletionRate) return {isHidden: true}
                if (!rateDifference) return {color: NAMED_INSIGHTS_COLORS.lightBlue}
                if (rateDifference >= 0) return {color: NAMED_INSIGHTS_COLORS.lightBlue}
                if (rateDifference < 0) return {color: NAMED_INSIGHTS_COLORS.red}
              })(),
              "Rate Difference": (() => {
                if (!objectiveCompletionRate || !rateDifference) return {isHidden: true}

                if (rateDifference >= 0)
                  return {
                    color: NAMED_INSIGHTS_COLORS.lightGreen,
                    name: "Overperforming by",
                    value: `+${rateDifference}%`,
                  }

                if (rateDifference < 0)
                  return {
                    color: NAMED_INSIGHTS_COLORS.red,
                    name: "Underperforming by",
                    value: `${rateDifference}%`,
                  }
              })(),
            }

            const fill = tooltipBuilder?.[name]?.color ?? color
            const key = tooltipBuilder?.[name]?.name ?? name
            const value = tooltipBuilder?.[name]?.value ?? `${innerPayload[dataKey]}%`
            const isHidden = tooltipBuilder?.[name]?.isHidden

            return (
              !isHidden && (
                <p key={`tooltip-${dataKey}`} style={{color: fill}}>{`${key} : ${value}`}</p>
              )
            )
          })}
      </Box>
    )
  }

  return null
}

TooltipContent.propTypes = {
  active: bool,
  label: string,
  payload: arrayOf(object),
  classes: object.isRequired,
}

export default TooltipContent
