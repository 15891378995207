import throttle from "lodash/throttle"
import {node} from "prop-types"
import {createContext, useContext, useEffect, useReducer, useState} from "react"

import storage from "lib/storage"

import CampaignBuilder from "./campaign-builder"
import reducer, {defaultState, partitionLast} from "./campaign-builder-reducer"

const storageKey = "atomic-assets:campaign-builder"
const storeState = throttle(state => {
  storage.setItem(storageKey, JSON.stringify(state))
}, 1000)

const CampaignBuilderContext = createContext()
CampaignBuilderContext.displayName = "CampaignBuilderContext"

export const setActiveStep = payload => ({type: "ACTIVE_STEP_SET", payload})
export const addObjectiveRow = payload => ({type: "OBJECTIVE_ROW_ADDED", payload})
export const updateObjectiveRow = payload => ({type: "OBJECTIVE_ROW_UPDATED", payload})
export const setCampaign = payload => ({type: "CAMPAIGN_SET", payload})

export const CampaignBuilderProvider = ({children}) => {
  const [state, dispatch] = useReducer(
    reducer,
    JSON.parse(storage.getItem(storageKey)) || defaultState
  )

  const hasMessage = !!state.objectiveRows.find(({messages}) => !!messages.length)
  const hasOneMessage = !!state.objectiveRows.find(({messages}) => messages.length === 1)
  const hasPage = !!state.objectiveRows.find(({page}) => !!page)
  const hasScheduledAt = state.campaignType === "mass-messaging" && state.campaignScheduledAt
  const [isOpen, setIsOpen] = useState(false)
  const onOpen = () => setIsOpen(true)
  const onClose = () => setIsOpen(false)

  useEffect(() => storeState(state), [state])
  useEffect(() => storeState.flush, [])

  const clearState = () => {
    dispatch(setCampaign(defaultState))
  }

  const {currentRow, otherRows} = partitionLast(state.objectiveRows)

  const startCampaign = ({type, ...attrs}) => {
    const objective = attrs.objective
    let row = objective ? {objective} : {}
    let activeStep = 4 // Review step

    switch (type) {
      case "page":
        row = {...row, page: attrs, messages: []}
        activeStep = 3 // Select messages step
        break

      case "sms":
      case "email":
        row = {...row, messages: [{type, ...attrs}]}
        break

      default:
        throw new Error("unknown content library item type specified")
    }

    dispatch(
      setCampaign({
        activeStep,
        campaignName: `${attrs.contentName} Campaign`,
        objectiveRows: [row],
      })
    )

    onOpen()
  }

  const value = {
    state,
    defaultState,
    currentRow,
    otherRows,
    content: {
      hasMessage,
      hasOneMessage,
      hasPage,
      hasScheduledAt,
    },
    isOpen,

    dispatch,
    onOpen,
    onClose,
    storeState,
    clearState,
    startCampaign,
  }

  return (
    <CampaignBuilderContext.Provider value={value}>
      <CampaignBuilder />
      {children}
    </CampaignBuilderContext.Provider>
  )
}

export const campaignBuilderContextProvider = Component => props => (
  <CampaignBuilderProvider>
    <Component {...props} />
  </CampaignBuilderProvider>
)

CampaignBuilderProvider.propTypes = {
  children: node.isRequired,
}

export const useCampaignBuilder = () => {
  const context = useContext(CampaignBuilderContext)

  return context
}
