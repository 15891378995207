import {Button, Link, Typography} from "@mui/material"
import {TableCell, TableRow} from "@mui/material"
import {lighten} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import cx from "classnames"
import {array, object, string} from "prop-types"
import {AiOutlineTrophy as RewardsIcon} from "react-icons/ai"
import {BiNetworkChart as CCEIcon} from "react-icons/bi"
import {
  FaCheckCircle as ApprovedIcon,
  FaArchive as ArchivedIcon,
  FaTools as DraftIcon,
  FaLeaf as LiveIcon,
  FaVolumeMute as MuteIcon,
  FaExclamationTriangle as NotApprovedIcon,
  FaStreetView as TargetingIcon,
  FaStar as TargetingPriorityIcon,
} from "react-icons/fa"
import {FiEdit as EditIcon, FiMap as MapIcon} from "react-icons/fi"
import {
  MdOutlinePendingActions as PendingIcon,
  MdOutlineRotateLeft as ReEnrollIcon,
} from "react-icons/md"
import {Link as RouterLink} from "react-router-dom"

import {distanceDateTime} from "lib/date-time-formatters"
import useFeatures from "lib/hooks/use-features"
import humanize from "lib/string/humanize"

const StatusIcon = ({status}) => {
  switch (status) {
    case "live":
      return <LiveIcon />
    case "draft":
      return <DraftIcon />
    case "approved":
      return <ApprovedIcon />
    case "not-approved":
      return <NotApprovedIcon />
    case "archived":
      return <ArchivedIcon />
    case "pending-approval":
      return <PendingIcon />
    default:
      return null
  }
}

const statusLabel = status => {
  switch (status) {
    case "not-approved":
      return "Rejected"
    case "pending-approval":
      return "Pending"
    default:
      return humanize(status)
  }
}

StatusIcon.propTypes = {
  status: string.isRequired,
}

const useStyles = makeStyles(theme => ({
  mainLink: {
    display: "block",
    marginBottom: theme.spacing(1),
    textDecoration: "none",
    color: theme.palette.text.primary,
  },
  campaignStatusBar: {
    display: "inline-flex",
    "& > *": {
      marginRight: theme.spacing(1),
    },
  },
  isMuted: {
    backgroundColor: theme.palette.error.main,
    color: "#ffffff",
    display: "inline-flex",
    height: 32,
    width: 32,
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(0.5),
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(-1),
  },
  statusIcon: {
    height: 30,
    // width: 32,
    borderRadius: 15,
    fontWeight: "bold",
    fontSize: 14,
    textTransform: "uppercase",
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(1),
    "& svg": {
      marginRight: theme.spacing(1),
    },
    "&.live": {
      backgroundColor: lighten(theme.palette.brand.lightBlue, 0.9),
      color: theme.palette.brand.lightBlue,
    },
    "&.draft": {
      backgroundColor: lighten(theme.palette.brand.darkBlue, 0.9),
      color: theme.palette.brand.darkBlue,
    },
    "&.approved": {
      backgroundColor: lighten(theme.palette.brand.lightGreen, 0.9),
      color: theme.palette.brand.lightGreen,
    },
    "&.not-approved": {
      backgroundColor: lighten(theme.palette.brand.lightCoral, 0.9),
      color: theme.palette.brand.lightCoral,
    },
    "&.archived": {
      backgroundColor: lighten(theme.palette.brand.darkestGray, 0.9),
      color: theme.palette.brand.darkestGray,
    },
    "&.pending-approval": {
      backgroundColor: lighten(theme.palette.brand.mediumGray, 0.9),
      color: theme.palette.brand.mediumGray,
    },
  },
  actions: {
    "& a": {
      display: "inline-flex",
      marginLeft: theme.spacing(1),
      alignItems: "center",
      "& svg": {
        marginRight: theme.spacing(1),
      },
    },
  },
  targetingPriorityIcon: {
    color: theme.palette.warning.main,
  },
}))

const hasColumn = (columns, column) => columns.some(({field}) => field === column)

const CampaignListRow = ({columns, row}) => {
  const classes = useStyles()
  const {hasFeature} = useFeatures()
  const {
    facts,
    id,
    insertedAt,
    isCceEnabled,
    isMuted,
    isRewardEnabled,
    isTargetingPriority,
    launchedAt,
    name,
    status,
    targetingOrder,
    type,
  } = row
  return (
    <TableRow key={id}>
      {hasFeature("campaign-approval") && (
        <TableCell style={{width: 32, textAlign: "center"}}>
          <div className={cx(classes.statusIcon, status)}>
            <StatusIcon status={status} /> {statusLabel(status)}
          </div>
        </TableCell>
      )}
      <TableCell>
        <Typography
          variant="subtitle1"
          className={classes.mainLink}
          component={RouterLink}
          to={`/admin/templates/${id}/journeys`}
        >
          {name}
        </Typography>
        <div className={classes.campaignStatusBar}>
          {!hasFeature("campaign-approval") && status === "archived" && (
            <ArchivedIcon size={18} title="Archived" />
          )}
          {isMuted && (
            <div className={classes.isMuted}>
              <MuteIcon size={18} title="Muted - This campaign can't send messages" />
            </div>
          )}
          {targetingOrder !== null && (
            <Link
              to={`/admin/templates/${id}/targeting`}
              title="Automatic Targeting"
              color="brand.darkBlue"
              component={RouterLink}
            >
              <TargetingIcon size={18} className={classes.targetingIcon} />
            </Link>
          )}
          {isTargetingPriority && (
            <Link
              to={`/admin/templates/${id}/targeting`}
              title="Priority Automatic Targeting"
              component={RouterLink}
              color="brand.darkBlue"
              sx={{position: "relative"}}
            >
              <TargetingIcon size={18} className={classes.targetingIcon} />
              <div style={{position: "absolute", top: -12, right: -8}}>
                <TargetingPriorityIcon size={12} className={classes.targetingPriorityIcon} />
              </div>
            </Link>
          )}
          {type === "tactical" && <ReEnrollIcon size={22} title="Re-Enrollment Allowed" />}
          {hasFeature("cross-channel-engagement") && isCceEnabled && (
            <CCEIcon size={18} title="Cross-Channel Engagement" />
          )}
          {isRewardEnabled && <RewardsIcon size={18} title="Reward" />}
        </div>
      </TableCell>
      {hasColumn(columns, "inserted_at") && <TableCell>{distanceDateTime(insertedAt)}</TableCell>}
      {hasColumn(columns, "launched_at") && (
        <TableCell>{launchedAt && distanceDateTime(launchedAt)}</TableCell>
      )}
      {hasColumn(columns, "min_contact_added_at") && (
        <TableCell>
          {facts?.minContactAddedAt && distanceDateTime(facts?.minContactAddedAt)}
        </TableCell>
      )}
      {hasColumn(columns, "max_contact_added_at") && (
        <TableCell>
          {facts?.minContactAddedAt && distanceDateTime(facts?.maxContactAddedAt)}
        </TableCell>
      )}
      {hasColumn(columns, "journey_count") && (
        <TableCell style={{textAlign: "center"}}>
          <div>
            <Typography variant="subtitle1">
              {(facts?.journeyCount || 0).toLocaleString()}
            </Typography>
            <Typography variant="caption">journeys</Typography>
          </div>
        </TableCell>
      )}
      <TableCell style={{width: 232}}>
        <div className={classes.actions}>
          <Button
            color="textPrimary"
            component={RouterLink}
            variant="body2"
            to={`/admin/templates/${id}/edit`}
          >
            <EditIcon className={classes.leftIcon} /> Edit
          </Button>

          <Button
            component={RouterLink}
            disabled={status === "archived"}
            to={`/admin/templates/${id}/journeys/new`}
          >
            <MapIcon className={classes.leftIcon} /> Send
          </Button>
        </div>
      </TableCell>
    </TableRow>
  )
}

CampaignListRow.propTypes = {
  columns: array.isRequired,
  row: object.isRequired,
}

export default CampaignListRow
