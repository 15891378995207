import {createCampaign} from "lib/api"

const stripFakeId = fullEntity => {
  if (!fullEntity) return

  const {id = "", ...attrs} = fullEntity

  return id.includes("new") ? attrs : fullEntity
}

// We have the server setting these
const stripTitle = entity => {
  if (!entity) return

  const {contentName, ...rest} = entity

  return rest
}

const saveCampaignFromState = ({
  campaignAudience,
  campaignName,
  campaignJourneyDurationHours,
  campaignScheduledAt,
  campaignType,
  campaignUseCase,
  objectiveRows,
}) =>
  createCampaign({
    template: {
      audience: campaignAudience,
      journeyDurationHours: campaignJourneyDurationHours,
      name: campaignName,
      scheduledAt: campaignScheduledAt,
      type: campaignType,
      useCase: campaignUseCase,
    },
    objectiveRows: objectiveRows.map(objectiveRow => ({
      id: objectiveRow.objective?.id,
      page: stripTitle(stripFakeId(objectiveRow.page)),
      messages: objectiveRow.messages?.map(stripFakeId).map(stripTitle),
    })),
  })

export default saveCampaignFromState
