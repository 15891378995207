import {
  Box,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import {arrayOf, oneOf, shape, string} from "prop-types"
import {useEffect, useState} from "react"

import {fetchContentLibrary} from "lib/api"

import {buildNewContentItem, filterItems, humanizeType} from "./helpers"
import MessageLibraryItem from "./message-library-item"

const groupByType = (items, objective) =>
  items.reduce(
    (groupedItems, item) => ({
      ...groupedItems,
      [item.type]: [...groupedItems[item.type], item],
    }),
    {
      sms: [buildNewContentItem("sms", objective)],
      email: [buildNewContentItem("email", objective)],
    }
  )

const MessageLibrary = ({
  contentType,
  campaignType,
  objective,
  selectedMessages = [],
  onChange,
}) => {
  const [activeContentType, setActiveContentType] = useState(contentType)
  const massMessaging = campaignType === "mass-messaging"

  const [library, setLibrary] = useState({})
  const [filter, setFilter] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (massMessaging) {
      setIsLoading(true)
      fetchContentLibrary({
        contentTypes: ["email"],
        isArchived: false,
        objective: objective?.id || null,
      }).then(libraryItems => {
        setIsLoading(false)
        setLibrary(groupByType(libraryItems, objective))
      })
    } else {
      setIsLoading(true)
      fetchContentLibrary({
        contentTypes: ["sms", "email"],
        isArchived: false,
        objective: objective?.id || null,
      }).then(libraryItems => {
        setIsLoading(false)
        setLibrary(groupByType(libraryItems, objective))
      })
    }
  }, [massMessaging, objective])

  const onChangeActiveContentType = ({target: {value}}) => {
    setActiveContentType(value)
  }

  const onChangeFilter = ({target: {value}}) => {
    setFilter(value)
  }

  const onSelectItem = item => {
    onChange(
      !!selectedMessages.find(m => m.id === item.id)
        ? selectedMessages.filter(m => m.id !== item.id)
        : [...selectedMessages, item]
    )
  }

  const classes = useStyles()

  return [
    <Box key="title" my={1}>
      <Typography>
        Showing messages related to <i>{objective?.name ?? "no objective"}</i>
      </Typography>
    </Box>,
    <Box className={classes.filters} key="filters">
      <TextField
        className={classes.searchField}
        onChange={onChangeFilter}
        placeholder="Search..."
      />
      {!massMessaging && (
        <FormControl>
          <RadioGroup onChange={onChangeActiveContentType} row={true} value={activeContentType}>
            <FormControlLabel control={<Radio color="primary" />} label="All" value="all" />
            <FormControlLabel control={<Radio color="primary" />} label="Email" value="email" />
            <FormControlLabel control={<Radio color="primary" />} label="SMS" value="sms" />
          </RadioGroup>
        </FormControl>
      )}
    </Box>,

    isLoading ? (
      <div className={classes.loading} key="loading">
        <CircularProgress />
      </div>
    ) : (
      Object.entries(library).map(
        ([category, items]) =>
          (activeContentType === "all" || activeContentType === category) && (
            <Box key={category}>
              <Typography>{humanizeType(category)}</Typography>
              <div className={classes.libraryItems}>
                {filterItems(items, filter.toLowerCase()).map(item => (
                  <MessageLibraryItem
                    isSelected={!!selectedMessages.find(m => m.id === item.id)}
                    item={item}
                    key={item.id}
                    onSelect={onSelectItem}
                  />
                ))}
              </div>
            </Box>
          )
      )
    ),
  ]
}

MessageLibrary.propTypes = {
  campaignType: oneOf(["standard", "mass-messaging", "tactical"]),
  contentType: oneOf(["all", "sms", "email"]),
  objective: shape({
    id: string,
    name: string,
  }),
  selectedMessages: arrayOf(
    shape({
      id: string,
    })
  ),
}

const useStyles = makeStyles(theme => ({
  filters: {
    display: "flex",
    justifyContent: "space-between",
  },
  searchField: {
    flex: 1,
    marginRight: theme.spacing(2),
    maxWidth: 400,
  },
  libraryItems: {
    display: "grid",
    gridColumnGap: theme.spacing(6),
    gridRowGap: theme.spacing(1),
    msGridRowGap: theme.spacing(1),
    fallbacks: [{display: "-ms-grid"}],
    gridTemplateColumns: "repeat(auto-fill, 150px)",
    msGridTemplateColumns: "repeat(auto-fill, 150px)",
  },
  loading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}))

MessageLibrary.defaultProps = {
  contentType: "all",
}

export default MessageLibrary
