import {Typography} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import {useEffect, useMemo, useState} from "react"
import {
  Bar,
  BarChart,
  LabelList,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts"

import {useInsights} from "components/template-insights/insights-context"

import {fetchInsightObjectivePerformance} from "lib/api"

import BarLabel from "./bar-label"
import BenchmarkLabel from "./benchmark-label"
import BenchmarkRateBarShape from "./benchmark-rate-bar-shape"
import CompletionRateBarShape from "./completion-rate-bar-shape"
import JourneyCountLabel from "./journey-count-label"
import LegendContent from "./legend-content"
import PercentageLabel from "./percentage-label"
import TooltipContent from "./tooltip-content"

const useStyles = makeStyles(theme => ({
  chart404: {
    color: theme.palette.brand.mediumGray,
    fontSize: 20,
    fontStyle: "italic",
    fontWeight: 500,
    marginLeft: -100,
    left: "50%",
    position: "absolute",
    top: 175,
  },
  chartContainer: {
    position: "relative",
  },
  header: {
    fontSize: 36,
    fontWeight: 200,
    marginBottom: theme.spacing(1),
  },
  subHeader: {
    fontSize: "12px",
    marginBottom: "24px",
  },
}))

const generateNoCompletionsText = arr => {
  var displayString = ""
  arr.forEach((name, idx) => {
    if (idx === 0) {
      displayString = name
    } else {
      displayString += `, ${name}`
    }
  })
  return displayString
}

const ObjectivePerformanceInsightsBarchart = () => {
  const [data, setData] = useState([])
  const [noCompletions, setNoCompletions] = useState([])
  const [domainX, setDomainX] = useState(100)

  const subHeaderText =
    "Journeys are considered eligible if a contact has not yet completed an objective with the journey is created."

  const classes = useStyles()
  const {
    insights: {objective_performance},
    addInsightsCache,
    templateId,
    filter,
  } = useInsights()
  const objectivePerformance = useMemo(() => objective_performance?.value.result || null, [
    objective_performance,
  ])

  const setXScale = newData => {
    // Inspect data to find our barchart scale. Break at the first > 50 value.
    setDomainX(
      newData?.find(
        ({benchmark, objectiveCompletionRate}) => benchmark > 50 || objectiveCompletionRate > 50
      )
        ? 100
        : 50
    )
  }

  useEffect(() => {
    fetchInsightObjectivePerformance(templateId, filter).then(addInsightsCache)
  }, [addInsightsCache, templateId, filter])

  useEffect(() => {
    if (!objectivePerformance) return
    const dataArray = []
    const noCompletionsArray = []
    objectivePerformance.forEach(obj => {
      if (obj.objectivesCompletedCount === 0) {
        noCompletionsArray.push(obj.name)
      } else {
        dataArray.push(obj)
      }
    })
    setData(dataArray)
    setXScale(dataArray)
    setNoCompletions(noCompletionsArray)
  }, [objectivePerformance])

  return (
    <div className={classes.chartContainer}>
      <Typography className={classes.header} variant="h5">
        Objective Performance Rate
      </Typography>
      <Typography className={classes.subHeader}>{subHeaderText}</Typography>
      <ResponsiveContainer height={data.length * 150 || 300}>
        <BarChart
          barCategoryGap="20%"
          barGap={0}
          layout="vertical"
          data={data}
          margin={{top: 20, right: 50, left: 0, bottom: 20}}
          barSize={30}
        >
          <XAxis
            type="number"
            domain={[0, domainX]}
            hide={data.length > 0}
            tickFormatter={tick => `${tick}%`}
          />
          <YAxis dataKey="name" type="category" width={0} />
          <Tooltip content={TooltipContent} cursor={false} />
          <Legend content={LegendContent} />
          <Bar
            barSize={40}
            dataKey="objectiveCompletionRate"
            legendType={"square"}
            name="Objective Completion Rate"
            shape={CompletionRateBarShape}
          >
            <LabelList dataKey="name" content={BarLabel} />
            <LabelList dataKey="name" type="category" content={<JourneyCountLabel data={data} />} />
            <LabelList dataKey="name" type="category" content={<PercentageLabel data={data} />} />
          </Bar>
          <Bar
            dataKey="benchmark"
            fill="gray"
            barSize={20}
            opacity={0.6}
            shape={<BenchmarkRateBarShape />}
          >
            <LabelList dataKey="benchmark" content={<BenchmarkLabel />} />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
      {noCompletions.length > 0 && (
        <>
          <Typography variant="body2" sx={{fontWeight: "bold"}}>
            There haven't been any completions for these objectives in this campaign yet
          </Typography>
          <Typography variant="body2">{generateNoCompletionsText(noCompletions)}</Typography>
        </>
      )}

      {data.length < 1 && <Typography className={classes.chart404}>No Data Found</Typography>}
    </div>
  )
}

export default ObjectivePerformanceInsightsBarchart
