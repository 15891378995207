import {arrayOf, number, shape, string} from "prop-types"

import {NAMED_INSIGHTS_COLORS} from "../insights-helpers"

const JourneyCountLabel = ({x, y, height, value, data}) => {
  const fullDataObject = data.find(item => item.name === value)
  const {eligibleJourneyCount, objectivesCompletedCount} = fullDataObject
  return (
    <text x={x} y={y + height + 40} fill={NAMED_INSIGHTS_COLORS.gray} fontSize="14px">
      {objectivesCompletedCount} out of {eligibleJourneyCount} eligible journeys
    </text>
  )
}

JourneyCountLabel.propTypes = {
  x: number,
  y: number,
  height: number,
  value: string,
  data: arrayOf(
    shape({
      name: string.isRequired,
      eligibleJourneyCount: number.isRequired,
      objectivesCompletedCount: number.isRequired,
    })
  ).isRequired,
}

export default JourneyCountLabel
