import withStyles from "@mui/styles/withStyles"
import cx from "classnames"
import {bool, object, string} from "prop-types"
import {useEffect, useState} from "react"

import AccountSubNav from "./account-sub-nav"
import ContactSubNav from "./contact-sub-nav"
import TeamSettingSubNav from "./team-setting-sub-nav"
import TeamSubNav from "./team-sub-nav"
import TemplateSubNav from "./template-sub-nav"

const SubNav = ({classes, route}) => {
  switch (route) {
    case "journeys":
    case "templates":
    case "campaigns":
      return <TemplateSubNav classes={classes} />
    case "contacts":
      return <ContactSubNav classes={classes} />
    case "team":
      return <TeamSubNav classes={classes} />
    case "settings":
      return <TeamSettingSubNav classes={classes} />
    case "account":
      return <AccountSubNav classes={classes} />
    default:
      return null
  }
}

SubNav.propTypes = {
  classes: object.isRequired,
  route: string,
}

const AdminSubNav = ({classes, isPreviewing, route}) => {
  const [shouldAnimate, setShouldAnimate] = useState(false)

  useEffect(() => {
    if (isPreviewing) setShouldAnimate(true)
  }, [isPreviewing])

  return (
    <div className={cx(classes.navWrapper, {[classes.withAnimation]: shouldAnimate})}>
      <nav className={classes.nav}>
        <SubNav classes={classes} route={route} />
      </nav>
    </div>
  )
}

AdminSubNav.propTypes = {
  classes: object.isRequired,
  isPreviewing: bool,
  route: string,
}

const NAV_HEIGHT = 44

const styles = theme => ({
  withAnimation: {
    "& $link": {
      animation: ".3s ease $slideIn",
      opacity: 0,
      animationFillMode: "forwards",
      "&:nth-child(1)": {animationDelay: 0},
      "&:nth-child(2)": {animationDelay: ".1s"},
      "&:nth-child(3)": {animationDelay: ".2s"},
      "&:nth-child(4)": {animationDelay: ".3s"},
      // the change in scale is intentional so it doesn't feel like it takes
      // forever to see all the links on longer lists. the difference between
      // items is imperceptable but across the whole list it makes a difference
      "&:nth-child(5)": {animationDelay: ".35s"},
      "&:nth-child(6)": {animationDelay: ".4s"},
      "&:nth-child(7)": {animationDelay: ".45s"},
      "&:nth-child(8)": {animationDelay: ".5s"},
      "&:nth-child(9)": {animationDelay: ".55s"},
      "&:nth-child(10)": {animationDelay: ".6s"},
    },
  },
  link: {
    alignItems: "center",
    color: "#F5F6F6",
    display: "inline-flex",
    height: "100%",
    marginRight: 100,
    textDecoration: "none",

    [theme.breakpoints.down("lg")]: {
      marginRight: 50,
    },

    "& > span": {
      borderBottom: "3px solid rgba(0, 0, 0, 0)",
      userSelect: "none",
      opacity: 0.85,
      padding: "2px",
    },

    "&:hover > span": {
      borderBottom: "3px solid #F5F6F6",
      opacity: 1,
    },

    "&.active > span": {
      borderBottom: "3px solid #F5F6F6",
      textShadow: "0 0 2px #489AD4",
      opacity: 1,
    },
  },
  "@keyframes slideIn": {
    from: {
      transform: `translateY(-${NAV_HEIGHT}px)`,
      opacity: 0,
    },
    to: {
      transform: "translateY(0)",
      opacity: 1,
    },
  },
  nav: {
    background: "#489AD4",
    color: "#F5F6F6",
    fontFamily: theme.typography.fontFamily,
    fontSize: 14,
    fontWeight: "bold",
    height: NAV_HEIGHT,
    padding: "0 60px",
    position: "fixed",
    whiteSpace: "nowrap",
    width: "100vw",
    zIndex: theme.zIndex.appBar,
    overflow: "auto",
  },
  navWrapper: {
    height: NAV_HEIGHT,
    position: "relative",
    zIndex: theme.zIndex.appBar,
  },
})

export default withStyles(styles)(AdminSubNav)
