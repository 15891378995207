import {ListItemIcon, ListItemText, MenuItem} from "@mui/material"
import {func, object} from "prop-types"
import {forwardRef, useState} from "react"
import {MdAddAPhoto as ImageIcon} from "react-icons/md"

import fileToContentBlock from "lib/file-to-content-block"

import FileBrowser from "../../../file-browser/file-browser"

const ImageButton = forwardRef(({classes, onClose, onAddContentBlock}, ref) => {
  const [isFileBrowserOpen, setFileBrowserOpen] = useState(false)

  const onClick = () => {
    setFileBrowserOpen(true)
    onClose()
  }

  // fileToContentBlock returns type of `image` or `document` depending on
  // what's uploaded/selected so it's a little more forgiving
  const onSelect = async file => {
    onAddContentBlock(await fileToContentBlock(file))
    setFileBrowserOpen(false)
  }

  const onCloseModal = () => {
    setFileBrowserOpen(false)
  }

  return (
    <>
      <MenuItem onClick={onClick}>
        <ListItemIcon classes={{root: classes.icon}} ref={ref}>
          <ImageIcon />
        </ListItemIcon>
        <ListItemText primary="Image" />
      </MenuItem>
      {isFileBrowserOpen ? (
        <FileBrowser
          isOpen={isFileBrowserOpen}
          onClose={onCloseModal}
          onSelect={onSelect}
          types={["image/png", "image/gif", "image/jpeg"]}
        />
      ) : null}
    </>
  )
})

ImageButton.propTypes = {
  classes: object.isRequired,
  onAddContentBlock: func.isRequired,
  onClose: func,
}

export {ImageIcon}

export default ImageButton
