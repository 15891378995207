import {arrayOf, number, shape, string} from "prop-types"

import {NAMED_INSIGHTS_COLORS} from "../insights-helpers"

const PercentageLabel = ({y, width, value, data}) => {
  const fullDataObject = data.find(item => item.name === value)
  const {benchmark, objectiveCompletionRate} = fullDataObject
  const fillColor =
    benchmark > objectiveCompletionRate
      ? NAMED_INSIGHTS_COLORS.red
      : NAMED_INSIGHTS_COLORS.lightBlue
  return (
    <text
      x={width + 10}
      y={y + 25}
      textAnchor="start"
      fill={fillColor}
      fontSize="18px"
      fontWeight="bold"
    >
      {objectiveCompletionRate}%
    </text>
  )
}

PercentageLabel.propTypes = {
  y: number,
  width: number,
  value: string,
  data: arrayOf(
    shape({
      name: string.isRequired,
      benchmark: number.isRequired,
      objectiveCompletionRate: number.isRequired,
    })
  ).isRequired,
}

export default PercentageLabel
