import {object, shape, string} from "prop-types"
import {Route, Switch} from "react-router-dom"

import AdvancedTeamInsights from "components/advanced-team-insights/advanced-team-insights"
import CtaSummaryContainer from "components/chromic-pdf/cta-summary-container"
import MessagePreview from "components/chromic-pdf/message-preview"
import CrossChannelEngagementSettings from "components/cross-channel-engagement/cross-channel-engagement-settings"
import ThemedCrossChannelEngagementEditor from "components/cross-channel-engagement/themed-cross-channel-engagement-editor"
import MessageEditor from "components/message-editor/message-editor"
import PageEditor from "components/page-editor/page-editor"
import TeamInsights from "components/team-insights/team-insights"

import useFeatures from "../../lib/hooks/use-features"
import AccessControlledRoute from "../access-control/access-controlled-route"
import AccountRouter from "../account-settings/account-router"
import AdminNav from "../admin-nav/admin-nav"
import CampaignsList from "../campaigns-list/campaigns-list"
import ContactRouter from "../contact-router/contact-router"
import ContactsShowContainer from "../contacts/contacts-show-container"
import NewContactContainer from "../contacts/new-contact-container"
import ContentLibrary from "../content-library/content-library"
import JourneysShowContainer from "../journeys-show/journeys-show-container"
import NewTemplateContainer from "../new-template/new-template-container"
import RewardRouter from "../reward/reward-router"
import Targeting from "../targeting/targeting"
import TeamRouter from "../teams/team-router"
import TeamSettingsRouterContainer from "../teams/team-settings-router-container"
import UserInviteFormContainer from "../teams/user-invite-form-container"
import TemplateRouter from "../template-router/template-router"
import TemplatesListContainer from "../templates-list/templates-list-container"

const AdminRouter = ({location, location: {hash}, match}) => {
  const {hasFeature} = useFeatures()

  const InsightsDashboard = hasFeature("advanced-insights") ? AdvancedTeamInsights : TeamInsights

  return (
    <div className="admin-router">
      {hash !== "#preview" && <AdminNav location={location} />}
      <Switch>
        <Route component={AccountRouter} path={`${match.url}/account`} />
        <Route component={TeamSettingsRouterContainer} path={`${match.url}/settings`} />
        <Route component={InsightsDashboard} exact={true} path={`${match.url}/insights`} />
        <AccessControlledRoute
          component={CrossChannelEngagementSettings}
          exact={true}
          requiredPermissions="templates:edit"
          path={`${match.url}/templates/engagement-channels`}
        />
        <AccessControlledRoute
          component={ThemedCrossChannelEngagementEditor}
          path={`${match.url}/templates/engagement-channels/:channelId/content-containers/:contentContainerId`}
          requiredPermissions="templates:edit"
        />
        <AccessControlledRoute
          component={Targeting}
          path={`${match.url}/templates/targeting`}
          requiredPermissions="targeting_conditions:edit"
        />
        <AccessControlledRoute
          component={MessageEditor}
          path={`${match.url}/templates/content-library/messages/:messageId`}
          requiredPermissions="templates:edit"
        />
        <AccessControlledRoute
          component={PageEditor}
          path={`${match.url}/templates/content-library/pages/:pageId`}
          requiredPermissions="templates:edit"
        />
        <AccessControlledRoute
          component={ContentLibrary}
          path={`${match.url}/templates/content-library`}
          requiredPermissions="templates:view"
        />
        <AccessControlledRoute
          component={NewTemplateContainer}
          path={`${match.url}/templates/new`}
          requiredPermissions="templates:edit"
        />
        <AccessControlledRoute
          component={MessageEditor}
          exact={true}
          path={`${match.url}/templates/:templateId/messages/:templateMessageId`}
          requiredPermissions="templates:edit"
        />
        <AccessControlledRoute
          component={MessagePreview}
          exact={true}
          path={`${match.url}/templates/:templateId/messages/:templateMessageId/chromic-pdf`}
          requiredPermissions="templates:view"
        />
        <AccessControlledRoute
          component={CtaSummaryContainer}
          exact={true}
          path={`${match.url}/templates/:templateId/cta-summary`}
          requiredPermissions="templates:view"
        />
        <AccessControlledRoute
          component={TemplateRouter}
          path={`${match.url}/templates/:templateId`}
          requiredPermissions="templates:view"
        />
        <Route component={TemplatesListContainer} path={`${match.url}/templates`} />
        <Route component={CampaignsList} path={`${match.url}/campaigns`} />
        <AccessControlledRoute
          component={JourneysShowContainer}
          path={`${match.url}/journeys/:journeyId`}
          requiredPermissions="contacts:view"
        />
        <AccessControlledRoute
          component={NewContactContainer}
          path={`${match.url}/contacts/new`}
          requiredPermissions="contacts:create"
        />
        <AccessControlledRoute
          component={ContactRouter}
          path={`${match.url}/contacts/batch-upload`}
          requiredPermissions={["contacts:create", "contacts:view"]}
        />
        <AccessControlledRoute
          component={ContactsShowContainer}
          path={`${match.url}/contacts/:contactId`}
          requiredPermissions="contacts:view"
        />
        <AccessControlledRoute
          component={ContactRouter}
          path={`${match.url}/contacts`}
          requiredPermissions="contacts:view"
        />
        <AccessControlledRoute
          component={UserInviteFormContainer}
          path={`${match.url}/team/invite-user`}
          requiredPermissions="team:users"
        />
        <AccessControlledRoute
          component={RewardRouter}
          path={`${match.url}/rewards`}
          requiredPermissions="rewards:manage"
        />
        <Route component={TeamRouter} path={`${match.url}/team`} />
      </Switch>
    </div>
  )
}

AdminRouter.propTypes = {
  location: object.isRequired,
  match: shape({
    url: string.isRequired,
  }).isRequired,
}

export default AdminRouter
