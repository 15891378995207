import {Button, Tooltip} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import mixpanel from "mixpanel-browser"
import {func, string} from "prop-types"
import {useCallback} from "react"
import {useEffect, useState} from "react"
import {RiArrowGoBackFill as BackArrowIcon} from "react-icons/ri"
import {TiPlus as PlusIcon} from "react-icons/ti"
import {Link as RouterLink, useHistory} from "react-router-dom"

import Box from "components/box/box"
import {tabular} from "components/table/table-state"

import {fetchContacts, fetchCurrentTeam, fetchTemplates} from "lib/api"
import useFeatures from "lib/hooks/use-features"
import storage, {sessionStore} from "lib/storage"

import AccessControlled from "../access-control/access-controlled"
import {
  campaignBuilderContextProvider,
  useCampaignBuilder,
} from "../campaign-builder/campaign-builder-context"
import DocumentTitle from "../document-title/document-title"
import ExportButton from "../export-button/export-button"
import Padded from "../padded/padded"
import DOTable from "../table/table"
import TitleBar from "../title-bar/title-bar"
import CampaignFilters from "./campaign-filters"
import CampaignListRow from "./campaign-list-row"

const useStyles = makeStyles(theme => ({
  campaignTable: {
    "& .journeyCount": {
      position: "relative",
      textAlign: "center",
      "& svg": {
        position: "absolute",
        left: "100%",
      },
    },
  },
}))

const defaultSorting = {
  sortColumn: "max_contact_added_at",
  sortDirection: "desc",
}

const campaignListConfig = "campaigns:dashboard:config"
const storeCampaignListConfig = state =>
  sessionStore.setItem(campaignListConfig, JSON.stringify(state))

export const isTemplateLimitReached = team =>
  !team ||
  (team.package === "starter" && team.countActiveTemplates >= team.org.starterPackageTemplateLimit)

const CampaignList = ({sortColumn, sortDirection, setTableState, updateStateForRequest}) => {
  const [initialFilters] = useState(() => {
    try {
      const item = sessionStore.getItem(campaignListConfig)
      return item ? JSON.parse(item) : defaultSorting
    } catch (e) {
      return defaultSorting
    }
  })
  const [isTableLoading, setTableLoading] = useState(true)
  const {onOpen: onOpenCampaignBuilder} = useCampaignBuilder()

  const [mayAddTemplates, setMayAddTemplates] = useState(undefined)

  useEffect(() => {
    fetchCurrentTeam({withOrg: true, withCountActiveTemplates: true}).then(team =>
      setMayAddTemplates(!isTemplateLimitReached(team))
    )
  }, [])

  const onFilter = ({sortColumn, sortDirection, ...filters}) => {
    storeCampaignListConfig({sortColumn, sortDirection, filters})
    refresh({page: 0, sortColumn, sortDirection, filters})
  }

  const onReset = () => {
    onFilter({...defaultSorting, filter: "", template_status: []})
  }

  const refresh = useCallback(
    attrs => {
      setTableLoading(true)
      const params = updateStateForRequest(attrs)
      fetchTemplates({
        ...params,
        withFacts: true,
        includeArchived: true,
        withFetchResponse: true,
      }).then(([rows, fetchResponse]) => {
        setTableState({rows, fetchResponse, ...attrs})
        setTableLoading(false)
      })
    },
    [setTableState, updateStateForRequest]
  )

  // FIXME - This eslint-disable-next-line is a workaround for an issue with table-state.js
  // The identites of the `updateStateForRequest` and `setTableState` functions are changing
  // between renders, causing the `useEffect` to fire on every render. This is a temporary
  // workaround until the issue is resolved.
  useEffect(() => {
    refresh({...initialFilters})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const {hasFeature} = useFeatures()
  const classes = useStyles()

  const renderNewCampaignOrTemplateButton = ({disabled}) =>
    hasFeature("atomic-assets") ? (
      <Button
        color="primary"
        onClick={onOpenCampaignBuilder}
        size="small"
        variant="contained"
        disabled={disabled}
      >
        <PlusIcon /> Build a new Campaign
      </Button>
    ) : (
      <Button
        color="primary"
        component={RouterLink}
        size="small"
        to="/admin/templates/new"
        variant="contained"
        disabled={disabled}
      >
        <PlusIcon /> Create a new Template
      </Button>
    )

  const history = useHistory()

  if (!hasFeature("campaign-dashboard") || !storage.getItem("beta:campaign-dashboard")) {
    history.push("/admin/templates")
    return null
  }

  const onUseTemplateDashboard = event => {
    event.preventDefault()
    mixpanel.track("disable campaign dashboard")
    storage.removeItem("beta:campaign-dashboard")
    history.push("/admin/templates")
    return null
  }

  return (
    <div data-testid="dashboard">
      <DocumentTitle title="Campaigns" />

      <TitleBar title="Campaign Dashboard">
        <AccessControlled requiredPermissions="templates:edit">
          {mayAddTemplates ? (
            renderNewCampaignOrTemplateButton({disabled: false})
          ) : (
            <Tooltip title="You cannot create a campaign without exceeding your Active Campaign Limit. Please archive one of your existing campaigns before creating a new campaign">
              <span>{renderNewCampaignOrTemplateButton({disabled: true})}</span>
            </Tooltip>
          )}
        </AccessControlled>
      </TitleBar>

      <Padded>
        <Box
          sx={{
            background: "#002956",
            color: "white",
            display: "flex",
            alignItems: "center",
            "& .label": {
              fontWeight: "bold",
              fontSize: 32,
              pr: 2,
            },
            "& .label + div": {
              pl: 2,
              borderLeft: "1px solid white",
            },
            "& div b": {
              fontSize: 17,
            },
            "& div p": {
              fontSize: 14,
            },
            "& button": {
              border: "2px solid #489AD4",
              background: "#002956",
            },
            "& button svg": {
              mr: 1,
            },
          }}
        >
          <div className="label">Beta</div>
          <div>
            <b>Do you like the new campaign dashboard?</b>
            <p>
              We're working on a new campaign dashboard to help you manage your campaigns more
              easily. We'll be making it the default dashboard soon, but you can switch back to the
              old one at any time.
              <br />
              <br />
              <Button size="small" variant="contained" onClick={onUseTemplateDashboard}>
                <BackArrowIcon /> This is too different, take me back!
              </Button>
            </p>
          </div>
        </Box>
      </Padded>
      <Padded role="list">
        <Box>
          <AccessControlled requiredPermissions="contacts:view">
            <ExportButton
              fetchRecords={fetchContacts}
              filename="contacts_unsubscribes"
              params={{isUnsubscribed: true}}
              title="Download Unsubscribes"
            />
          </AccessControlled>

          <DOTable
            allowColumnCustomization={true}
            classes={{tableRoot: classes.campaignTable}}
            headers={[
              {field: "status", isLocked: true, label: "", sortable: false},
              {field: "name", isLocked: true, label: "Campaign", sortable: true},
              {field: "inserted_at", label: "Created", sortable: true},
              hasFeature("campaign-approval") && {
                field: "launched_at",
                label: "Launched",
                sortable: true,
              },
              {
                field: "min_contact_added_at",
                label: "First Journey Created",
                sortable: true,
              },
              {
                field: "max_contact_added_at",
                isDefault: true,
                label: "Last Journey Created",
                sortable: true,
              },
              {
                field: "journey_count",
                isDefault: true,
                label: "Journeys",
                sortable: true,
                classes: "journeyCount",
              },
              {field: "actions", isLocked: true, label: "", sortable: false},
            ].filter(Boolean)}
            filterComponent={
              <CampaignFilters
                onReset={onReset}
                onSubmit={onFilter}
                sortColumn={sortColumn}
                sortDirection={sortDirection}
              />
            }
            isTableLoading={isTableLoading}
            noResults="No campaigns found."
            paginationEnabled={true}
            refresh={refresh}
            storageName="campaigns-list"
          >
            {({id, ...campaign}, index, columns) => (
              <CampaignListRow key={id} columns={columns} row={{id, ...campaign}} />
            )}
          </DOTable>
        </Box>
      </Padded>
    </div>
  )
}

CampaignList.propTypes = {
  setTableState: func.isRequired,
  sortColumn: string,
  sortDirection: string,
  sortKey: string,
  updateStateForRequest: func.isRequired,
}

export default campaignBuilderContextProvider(
  tabular({...defaultSorting, useQueryParams: true})(CampaignList)
)
