import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Switch,
  TextField,
} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import {func, object, string} from "prop-types"
import {useCallback} from "react"

import useFeatures from "lib/hooks/use-features"
import {formify} from "lib/hooks/use-form"

import DOSelect from "../do-select/do-select"

const AllStatuses = [
  {label: "Approved", value: "approved"},
  {label: "Archived", value: "archived"},
  {label: "Draft", value: "draft"},
  {label: "Live", value: "live"},
  {label: "Not Approved", value: "not-approved"},
  {label: "Pending Approval", value: "pending-approval"},
]

const allStatusesExcept = value => AllStatuses.filter(s => s.value !== value)

const useStyles = makeStyles(theme => ({
  filterText: {
    flex: 1,
    marginLeft: theme.spacing(0.5),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  sortingSelect: {
    width: 220,
  },
}))

const CampaignFilter = ({change, field, inputs, onSubmit, onReset, sortColumn, sortDirection}) => {
  const classes = useStyles()
  const {hasFeature} = useFeatures()
  const statusField = field("template_status", {defaultValue: []})
  const sortField = field("sort")
  const onSortFieldChange = useCallback(
    event => {
      const [sortColumn, sortDirection] = event.target.value.split("-")
      onSubmit({sortColumn, sortDirection})
    },
    [onSubmit]
  )
  const handleDisplayArchivedChange = useCallback(
    event => {
      const {checked} = event.target
      const newStatus = checked ? [] : allStatusesExcept("archived").map(s => s.value)
      change("template_status", newStatus)
    },
    [change]
  )

  return (
    <>
      <FormControlLabel
        className={classes.filterText}
        control={
          <TextField fullWidth={true} placeholder="Filter campaigns..." {...field("filter")} />
        }
        label=""
      />
      {!hasFeature("campaign-approval") && (
        <FormControlLabel
          control={
            <Switch
              {...statusField}
              checked={statusField.value?.includes("archived") || statusField.value?.length === 0}
              color="primary"
              onChange={handleDisplayArchivedChange}
            />
          }
          label="Display Archived"
        />
      )}

      {hasFeature("campaign-approval") && (
        <FormControl margin="none" sx={{minWidth: "150px", marginRight: "10px"}}>
          <InputLabel>Filter by Status</InputLabel>
          <DOSelect
            multiple={true}
            {...statusField}
            renderValue={() => (
              <div style={{maxWidth: "150px", overflow: "hidden", textOverflow: "ellipsis"}}>
                {statusField.value
                  .map(value => AllStatuses.find(s => s.value === value).label)
                  .join(", ")}
              </div>
            )}
          >
            {AllStatuses.map(({label, value}) => (
              <MenuItem key={value} value={value}>
                <Checkbox checked={statusField.value.includes(value)} color={"primary"} />
                {label}
              </MenuItem>
            ))}
          </DOSelect>
        </FormControl>
      )}

      <FormControl margin="none">
        <InputLabel>Sort By</InputLabel>
        <DOSelect
          className={classes.sortingSelect}
          {...sortField}
          onChange={onSortFieldChange}
          value={`${sortColumn}-${sortDirection}`}
        >
          <MenuItem value="name-asc">Name (A to Z)</MenuItem>
          <MenuItem value="name-desc">Name (Z to A) </MenuItem>
          <MenuItem value="inserted_at-asc">Date Added (oldest first)</MenuItem>
          <MenuItem value="inserted_at-desc">Date Added (newest first)</MenuItem>
          {hasFeature("campaign-approval") && (
            <MenuItem value={`launched_at-desc`}>Launch Date (newest first)</MenuItem>
          )}
          {hasFeature("campaign-approval") && (
            <MenuItem value={`launched_at-asc`}>Launch Date (oldest first)</MenuItem>
          )}
          <MenuItem value="min_contact_added_at-asc">First Journey Created (oldest first)</MenuItem>
          <MenuItem value="min_contact_added_at-desc">
            First Journey Created (newest first)
          </MenuItem>
          <MenuItem value="max_contact_added_at-asc">
            Latest Journey Created (oldest first)
          </MenuItem>
          <MenuItem value="max_contact_added_at-desc">
            Latest Journey Created (newest first)
          </MenuItem>
          <MenuItem value="journey_count-desc">Most journeys</MenuItem>
          <MenuItem value="journey_count-asc">Least journeys</MenuItem>
        </DOSelect>
      </FormControl>
      <Button color="grey" onClick={onReset}>
        reset
      </Button>
    </>
  )
}

CampaignFilter.propTypes = {
  change: func.isRequired,
  field: func.isRequired,
  inputs: object.isRequired,
  onSubmit: func.isRequired,
  onReset: func.isRequired,
  sortColumn: string,
  sortDirection: string,
}

export default formify({
  autoSubmitOnChange: true,
  autoSubmitDebounceTime: 1000,
  enableReinitialize: true,
})(CampaignFilter)
