import {object, string} from "prop-types"
import {Rectangle, Surface} from "recharts"

const LegendIcon = ({fill}) => (
  <Surface
    height={14}
    style={{
      display: "inline-block",
      verticalAlign: "middle",
      marginRight: 4,
    }}
    viewBox={{height: 32, width: 32, x: 0, y: 0}}
    width={14}
  >
    <Rectangle fill={fill} height={32} width={32} />
  </Surface>
)

LegendIcon.propTypes = {
  classes: object,
  fill: string,
}

export default LegendIcon
