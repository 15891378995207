import {Box, FormControlLabel, Radio, RadioGroup, TextField} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import {DateTimePicker} from "@mui/x-date-pickers/DateTimePicker"
import cx from "classnames"
import {func} from "prop-types"
import {useEffect, useState} from "react"

import SuperheroBuilder1 from "../superhero/builder-1"

const useStyles = makeStyles(theme => ({
  bigSpace: {
    marginBottom: theme.spacing(4),
  },
}))

const setCampaignSchedule = payload => ({type: "CAMPAIGN_SCHEDULE_SET", payload})

const AddScheduleStep = ({dispatch}) => {
  const [selectedOption, setSelectedOption] = useState(null)
  const [selectedDateTime, setSelectedDateTime] = useState(null)
  const classes = useStyles()

  const onChangeActiveScheduleType = ({target: {value}}) => {
    setSelectedOption(value)
  }

  const handleDatePickerChange = dateTime => {
    setSelectedDateTime(dateTime)
  }

  useEffect(() => {
    if (selectedOption === "immediate") {
      dispatch(setCampaignSchedule("immediate"))
    } else if (selectedOption === "schedule" && selectedDateTime) {
      dispatch(setCampaignSchedule(selectedDateTime))
    }
  }, [dispatch, selectedDateTime, selectedOption])

  return (
    <>
      <SuperheroBuilder1 style={{width: 320, float: "right"}} />
      {/* Extend box width to accomodate re-entry control label */}
      <Box maxWidth={460} my={4}>
        <RadioGroup
          name="scheduled-send"
          onChange={onChangeActiveScheduleType}
          value={selectedOption}
        >
          <FormControlLabel
            className={cx(classes.bigSpace)}
            control={<Radio color="primary" />}
            label="Send Immediately"
            labelPlacement="end"
            value="immediate"
          />
          <FormControlLabel
            control={<Radio color="primary" />}
            label="Send on schedule"
            labelPlacement="end"
            value="schedule"
          />
          <Box mx={3}>
            <DateTimePicker
              inputFormat="MM/dd/yyyy hh:mm a"
              margin="normal"
              onChange={handleDatePickerChange}
              renderInput={params => <TextField {...params} variant="standard" />}
              placeholder="Click to Schedule"
              value={selectedDateTime}
            />
          </Box>
        </RadioGroup>
      </Box>
    </>
  )
}

AddScheduleStep.propTypes = {
  dispatch: func.isRequired,
}

export default AddScheduleStep
